import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SiteNav from "../components/SiteNav/SiteNav"
import "./style.css"
export default ({ data, pageContext }) => {
  return (
    <Layout>
      <SEO title={pageContext.moreInformation[0].title} />

      <div className="pure-g">
        <div className="pure-u-5-5 pure-u-md-1-5">
          <SiteNav />
        </div>

        <div className="pure-u-5-5 pure-u-md-4-5">
          <h2>{pageContext.moreInformation[0].title}</h2>
          <div
            style={{
              backgroundColor: `#f5f5f5`,
              padding: "10px",
              borderRadius: "15px",
            }}
          >
            {data.allFile.edges.map((item, key) => {
              return (
                <span className="img-span" key={key}>
                  <img alt={item.node.name} src={item.node.publicURL} />
                </span>
              )
            })}
          </div>
          {pageContext.moreInformation[0].description.length > 0 && (
            <blockquote style={{ backgroundColor: "ivory" }}>
              {pageContext.moreInformation[0].description}
            </blockquote>
          )}
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    allFile(
      filter: { sourceInstanceName: { eq: $slug }, extension: { ne: "json" } }
    ) {
      edges {
        node {
          id
          childImageSharp {
            id
          }
          publicURL
          name
        }
      }
    }
  }
`
